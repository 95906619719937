import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Component/Navbar';
import Home from './Component/Home';
import About from './Component/About';
import Gallery from './Component/Gallery';
import Contact from './Component/Contact';


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className='main'>
      <Router  basename=''>
        <Navbar />
        <Routes>
          
          <Route path="*" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Contact" element={<Contact />} />

        </Routes>

      </Router>
    </div>
  );
}

export default App;
