import React from 'react'
import Welcome from './Welcome'
import Footer from './Footer'



const About = () => {
  return (
    <>
      <Welcome />
      <Footer />

    </>
  )
}

export default About