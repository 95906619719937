import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import bg1 from './Image/bscele1.png';
import bg2 from './Img/meetings.jpg';
import bg3 from './Img/kingship02.jpg';
import logo from './Img/logo.png';

const AppNavbar = () => {
  const [bgIndex, setBgIndex] = useState(0);

  const backgroundImages = [bg1, bg2, bg3];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Increment the background index in a cyclic manner
      setBgIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 5000); // Change image every 5 seconds (adjust as needed)

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);


  const headerStyle = {
    backgroundImage: `url(${backgroundImages[bgIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '550px', // Adjust the height as needed
  };

  return (
    <div className="header" style={headerStyle}>

      <div className='navigation'>

        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Navbar.Brand href="#"><img src={logo} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="mr-auto">

              <Nav.Link as={Link} to='/'>Home</Nav.Link>
              <Nav.Link as={Link} to='/About'>About</Nav.Link>
              <Nav.Link as={Link} to='/Gallery'>Gallery</Nav.Link>
              <Nav.Link as={Link} to='/Contact'>Contact</Nav.Link>

            </Nav>

          </Navbar.Collapse>

        </Navbar>
      </div>

      <h1>Welcome To B. S. Celebrations Banquets Rooms  </h1>
      <h2>Enjoy A Luxury Experience </h2>






    </div>
  );
};

export default AppNavbar;
