import React from 'react'

const Maps = () => {
    return (
        <div className='maps'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28500.07259400784!2d80.91763200000001!3d26.760038!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb0dd5586e3f%3A0x6ba5820214eb6de!2sB.S.Celebration%20Banquets%20%26%20Rooms!5e0!3m2!1sen!2sin!4v1706511812044!5m2!1sen!2sin" style={{ width: '100%', height:'100%'}}></iframe>


        </div>
    )
}

export default Maps
