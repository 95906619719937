import React from 'react';


import Vid1 from './Videos/vid1 (1).mp4';
import Vid2 from './Videos/vid1 (2).mp4';
import Vid3 from './Videos/vid1 (3).mp4';
import Vid4 from './Videos/vid1 (4).mp4';
import Vid5 from './Videos/vid1 (5).mp4';

const Videos = () => {
  return (
   <>

   <br/><br/>
   
   <center><h1>Our Videos </h1></center>
   
   <div className="videos-container">
      <video controls>
        <source src={Vid1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video controls>
        <source src={Vid2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video controls>
        <source src={Vid3} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video controls>
        <source src={Vid4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video controls>
        <source src={Vid5} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video controls>
        <source src={Vid5} type="video/mp4" />
        Your browser does not support the video tag.
      </video>


    </div>
   
   
   </>
  );
}

export default Videos;
