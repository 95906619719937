import React from 'react';
import Gal1 from './Image/cb14.png';
import Gal2 from './Image/cb15.png';
import Gal3 from './Image/cb16.png';
import Gal4 from './Img/caterers.jpeg';
import Gal5 from './Img/caterers.png';
import Gal6 from './Img/chhota-imambara.jpg';
import Gal7 from './Img/dinning.jpg';
import Footer from './Footer';

const Gallery = () => {
  return (

    <>

    <center><h1>Gallery</h1></center>

      <div className='gallery'>
        <div className='image-container'>
          <img src={Gal1} alt='Dining-04' />
        </div>
        <div className='image-container'>
          <img src={Gal2} alt='Event-06' />
        </div>
        <div className='image-container'>
          <img src={Gal3} alt='Birth' />
        </div>
        <div className='image-container'>
          <img src={Gal4} alt='Caterers' />
        </div>
        <div className='image-container'>
          <img src={Gal5} alt='Caterers' />
        </div>
        <div className='image-container'>
          <img src={Gal6} alt='Chhota Imambara' />
        </div>
        <div className='image-container'>
          <img src={Gal7} alt='Dinning' />
        </div>
      </div>


      <Footer />

    </>
  );
}

export default Gallery;
