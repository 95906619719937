import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';

const Header = () => {
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState('');
  const [adultsCount, setAdultsCount] = useState(1);
  const [childrenCount, setChildrenCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRoomChange = (event) => {
    setSelectedRoom(event.target.value);
  };

  const handleAdultsChange = (event) => {
    setAdultsCount(parseInt(event.target.value, 10));
  };

  const handleChildrenChange = (event) => {
    setChildrenCount(parseInt(event.target.value, 10));
  };

  const handleCheckAvailability = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (

    <div>



      {/* <div className='heading'>

        <div className='heading2'>
          <button>Flight</button>
        </div>

        <div className='heading2'>
          <button>Hotel</button>

        </div>

        <div className='heading2'>
          <button>Bus</button>
        </div>


      </div> */}


      <div className='checks'>
        <div className='checkout date-picker'>
          <p>Check-in date</p>
          <DatePicker selected={checkInDate} onChange={(date) => setCheckInDate(date)} />
        </div>

        <div className='checkout date-picker'>
          <p>Check-out date</p>
          <DatePicker selected={checkOutDate} onChange={(date) => setCheckOutDate(date)} />
        </div>

        <div className='checkout'>
          <p>Rooms</p>
          <select value={selectedRoom} onChange={handleRoomChange}>
            <option value=''>Select Room</option>
            <option value='family'>Family Room</option>
            <option value='deluxe'>Deluxe Room</option>
            <option value='classic'>Classic Room</option>
            <option value='superior'>Superior Room</option>
            <option value='luxury'>Luxury Room</option>
          </select>
        </div>

        <div className='checkout'>
          <p>Adults</p>
          <select value={adultsCount} onChange={handleAdultsChange}>
            {[1, 2, 3, 4, 5].map((count) => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </select>
        </div>

        <div className='checkout'>
          <p>Children</p>
          <select value={childrenCount} onChange={handleChildrenChange}>
            {[0, 1, 2, 3, 4].map((count) => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </select>
        </div>

        <div className='checkout'>
          <button onClick={handleCheckAvailability}>Check Availability</button>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel='Book Options'
        >
          <h2>Book Options</h2>
        
          <button onClick={handleCloseModal}>Close</button>
        </Modal>
      </div>

















    </div>

  );
};

export default Header;
