import React from 'react'


import Room1 from './Img/image_1.jpg'
import Room2 from './Img/caterers.png'
import Room3 from './Img/caterers.jpeg'
import Room4 from './Img/vag.png'
import Room5 from './Img/wedding.jpg'
import Room6 from './Img/birth.jpg'
import Room7 from './Img/ring.jpg'
import Room8 from './Img/tilak.jpg'

const Recentblog = () => {
    return (

        <>
            <br /><br />
            <center>
                <h1>We Offers </h1>
            </center>



            <div className='slide'>

                <div className='slide2'>
                    <img src={Room5} />
                    <h1>Wedding Party  </h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div>

                <div className='slide2'>
                    <img src={Room3} />
                    <h1>Reception</h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div>

                <div className='slide2'>
                    <img src={Room7} />
                    <h1> Ring Ceremony</h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div>

                <div className='slide2'>
                    <img src={Room8} />
                    <h1>Tilak Party </h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div>

            </div>



            <div className='slide'>

                


                <div className='slide2'>
                    <img src={Room6} />
                    <h1>Birthday Party</h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div>


                <div className='slide2'>
                    <img src={Room1} />
                    <h1>Corporate Event </h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div>

                <div className='slide2'>
                    <img src={Room2} />
                    <h1>Best Catering</h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div>






                {/* <div className='slide2'>
                    <img src={Room4} />
                    <h1>Vegan Menu</h1>
                    <p>B.S.Celebration Banquets & Rooms</p>
                </div> */}

            </div>


        </>
    )
}

export default Recentblog