import React from 'react'
import imsta from './Image/cb10.png';

const Welcome = () => {
    return (
        <div className='welcome'>

            <div className='welcome2' >
                <img src={imsta} />
            </div>


            <div className='welcome3'>

                <h3>Welcome To B.S.Celebration Banquets & Rooms</h3>

                <h1>Book To Banquet Hall In Lucknow</h1>

                <p>To organize an event like wedding both bride and groom want to make it extremely fanfare imprinting its memories in the most mesmerizing way in people’s mind. Hence, B.S.Celebration Banquets & Rooms is a perfect epitome to organize a wedding. This is because B.S.Celebration Banquets & Rooms is well-known as best affordable AC Banquet Hall in Lucknow which is meant for both the small or large scale gathering.</p>
                <p>Guest welcoming
                    Barat Welcoming
                    Catering
                    Decoration
                    Rooms for bride and groom
                    Stage
                    Jai Mala
                    Photography
                    Everything goes in a perfect soother manner with an implementation of timely executed services. Therefore, Ac marriage halls in Lucknow are meant for a perfect marriage execution which prevents suffocation with its effective AC’s when it comes to the large events and gathering.</p>





                <button>Read More </button>


            </div>


        </div>
    )
}

export default Welcome