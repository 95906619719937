import React from 'react'

const Footer = () => {
    return (

        <>

            <div className='footer'>

                <div className='foot2'>
                    <h1>B. S. Celebration Banquets</h1>
                    <br /><br />
                    <p style={{ textAlign: 'left', marginLeft: '10px', fontSize: '16px' }}>To organize an event like Wedding both bride and groom want to make it extremely fanfare imprinting its memories in the most mesmerizing way in people’s mind. Hence, B.S.Celebration Banquets & Rooms is a perfect epitome to organize a Wedding. This is because B.S.Celebration Banquets & Rooms is well-known as best affordable AC Banquet Hall in Lucknow which is meant for both the small or large scale gathering.</p>
                </div>

                <div className='foot2'>
                    <h1>Useful Links</h1>

                    <br /><br />

                    <p>Blogs</p>
                    <p>Rooms</p>
                    <p>Ground </p>
                    <p>Parking</p>

                </div>

                <div className='foot2'>
                    <h1>Privacy</h1>
                    <br /><br />
                    <p>Home</p>
                    <p>About Us </p>
                    <p>Contact US </p>
                    <p>Services</p>
                </div>


                <div className='foot2'>

                    <h1>Reach Us </h1>

                    <br /> <br />

                    <p> <i class="fa fa-map-marker"></i> Opps. Manipal Public School, Omaxe City, Bijnor, Tiraha, Lucknow, Uttar Pradesh 226002</p>
                    <p> <i class="fa fa-phone"></i> +91-9415517850 <i class="fa fa-phone"></i> 9838609591</p>
                    <p> <i class="fa fa-envelope"></i> arrus6108@gmail.com</p>

                </div>

            </div>


            <div className='diaclamer'>

                <h1 style={{ backgroundColor: '#3b5998' }}><i class="fa fa-facebook"></i></h1>
                <h1 style={{ backgroundColor: '#1DA1F2' }}><i class="fa fa-twitter"></i></h1>
                <h1 style={{ backgroundColor: '#833AB4' }}><i class="fa fa-instagram"></i></h1>
                <h1 style={{ backgroundColor: '#25D366' }}><a href='https://wa.me/9219198616' target="_blank"><i class="fa fa-whatsapp"></i></a></h1>


                <p>Copyright ©2024 All rights reserved | Designed , Developed   by Creative Digital word  </p>
            </div>



        </>
    )
}

export default Footer