import React from 'react'
import Header from './Header'
import Welcome from './Welcome'
import Rooms from './Rooms'
import Recentblog from './Recentblog'
import Footer from './Footer'
import Chart from './Chart'
import Jsondata from './Jsondata'
import Maps from './Maps'
import Videos from './Videos'

const Home = () => {
  return (

    <>

      <div className='main'>

        <Header />
        <Welcome />
        <Rooms />
        <Recentblog />

        <br />

        {/* <Chart /> */}
        <Videos/>

        {/* <Jsondata/> */}

        <Maps />
        <Footer />


      </div>

    </>

  )
}

export default Home   