import React from 'react'

import Room1 from './Img/room-1.jpg'
import Room2 from './Img/room-2.jpg'
import Room3 from './Img/room-3.jpg'
import Room4 from './Image/cb9.png'
import Room5 from './Image/cb2.jpg'
import Room6 from './Image/cb3.jpg'

import Room7 from './Image/cb8.jpg'
import Room8 from './Image/cb11.png'

const Rooms = () => {
    return (

        <div className='ourroom'>

            <h1>Our Rooms ( 16 Rooms)</h1>

            <div className='rooms'>

                <div className='rooms2'>
                    <img src={Room1} />
                    <h2> Room </h2>
                  
                   <p>The B.S. Celebrations  offers  A.C. Rooms with King Size  comfy beds , En -suite bathroom with shower  cubicle  and geyser.  T.V.  added amenities ideally suited for  Wedding  Guests and  Business Traveler. This rooms offer a complimentary internet and all standard amenities.</p>
                </div>


                <div className='rooms2'>
                    <img src={Room2} />
                    <h2> Room </h2>
                  
                    <p>The B.S. Celebrations offers  A.C.  Rooms with King Size comfy beds , En -suite bathroom with shower cubicle and geyser.  T.V. added amenities ideally suited for Wedding Guests and Business Traveler. This rooms offer a complimentary internet and all standard amenities.</p>
                </div>

                <div className='rooms2'>
                    <img src={Room3} />
                    <h2> Room</h2>
                   
                    <p>The B.S. Celebrations offers  A.C.  Rooms with King Size comfy beds , En -suite bathroom with shower cubicle  and geyser.  T.V. added amenities ideally suited for Wedding Guests and Business Traveler. This rooms offer a complimentary internet and all standard amenities.</p>
                </div>

            </div>


            <h1>Our Facility </h1>

            <div className='rooms'>

                <div className='rooms2'>
                    <img src={Room4} />
                    <h2>Banquet-1 </h2>
                    <p>With a Spacious <b>6500 sqft</b> area, Banquet-1 provides an ideal setting for Weddings, Corporate Gatherings, or milestone celebrations. Our versatile space, designed to accommodate your needs, ensures a perfect venue for any event with a Capacity to comfortably host  <b> 450 to 500 Guests.</b></p>
                </div>


                <div className='rooms2'>
                    <img src={Room5} />
                    <h2>Banquet-2 </h2>
                    <p>With a Spacious <b>6500 sqft</b> area, Banquet-1 provides an ideal setting for Weddings, Corporate Gatherings, or milestone celebrations. Our versatile space, designed to accommodate your needs, ensures a perfect venue for any event with a Capacity to comfortably host  <b> 450 to 500 Guests.</b></p>
                    
                </div>

                <div className='rooms2'>
                    <img src={Room6} />
                    <h2>Banquet-3   </h2>
                  <p>With a cozy <b>2500 sqft</b> area, Banquet-3 provides the perfect setting for intimate gatherings, particularly well-suited for Mandap ceremonies  offering a capacity for up to <b> 100 persons.</b></p>
                    
                </div>

            </div>


            {/* <div className='rooms'>

                <div className='rooms2'>
                    <img src={Room1} />
                    <h2>Total 16 rooms with full aminities TV ac geyser wifi king size bed</h2>
                    <p>$130.00 <span>Per night</span></p>
                    <h5>View Banquet Details <i class="fa fa-long-arrow-right"></i> </h5>
                </div>


                <div className='rooms2'>
                    <img src={Room7} />
                    <h2>Banquet 1 & Banquet 2 total persons party capacity 450 persons</h2>
                    <p>$600.00 <span>Per night</span></p>
                    <h5>Banquet 1 & Banquet 2 total persons party capacity 450 persons </h5>
                </div>

                <div className='rooms2'>
                    <img src={Room8} />
                    <h2>Banquet 1 only 200 persons party capacity</h2>
                    <p>$800.00 <span>Per night</span></p>
                    <h5>View Banquet Details <i class="fa fa-long-arrow-right"></i> </h5>
                </div>

            </div> */}


        </div>
    )
}

export default Rooms
